
import { defineComponent, ref, onMounted } from "vue";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import {
  IGetProductCategoryForViewDto,
  IProductCategoryDto,
} from "@/shared/service-proxies/service-proxies";
import { ProductCategoryFilter } from "@/models";
import AJLoader from "@/components/AjaxLoader.vue";
import ProductCategoryModal from "@/components/modals/forms/ProductCategoryModal.vue";
import ViewProductCategoryModal from "@/components/modals/forms/ViewProductCategoryModal.vue";
import useProducts from "@/composables/useProducts";
import DeleteItemService from "@/core/services/DeleteItemService";
import { useStore } from "vuex";

export default defineComponent({
  name: "Product-categories",
  components: {
    Datatable,
    AJLoader,
    ProductCategoryModal,
    ViewProductCategoryModal,
  },
  setup() {
    const { productCategories, getProductCategories, deleteProductCategory } =
      useProducts();
    const breadcrumbs = {
      title: "Product Categories",
      breadcrumbs: ["Product Categories"],
    };
    const checkedCustomers = ref([]);

    const store = useStore();
    const config = store.getters.getConfigurations;
    const permissions = config.result.auth.grantedPermissions;

    let loading = ref<boolean>(true);
    const showAdvanceFilter = ref<boolean>(false);

    let isEditMode = ref<boolean>(false);

    const defaultModel = ref({
      productCatgoryName: "",
      productCategoryDescription: "",
      id: "",
    }) as unknown as IProductCategoryDto;

    const defaultFilter = ref({
      skipCount: 0,
      maxResultCount: 20,
    } as unknown as ProductCategoryFilter);

    let modelValue = ref<IProductCategoryDto>(defaultModel);

    const tableHeader = ref([
      {
        name: "Actions",
        key: "actions",
      },
      {
        name: "Category name",
        key: "productCategoryName",
        sortable: true,
      },
      {
        name: "Category description",
        key: "productCategoryDescription",
        sortable: true,
      },
    ]);

    const tableData = ref<Array<IGetProductCategoryForViewDto>>([]);

    const initialData = ref<Array<IGetProductCategoryForViewDto>>([]);

    onMounted(async () => {
      setCurrentPageBreadcrumbs("Product Categories", [
        "Product categories header info",
      ]);

      await getData();
    });

    const getData = async () => {
      loading.value = true;

      await getProductCategories(
        defaultFilter.value as unknown as ProductCategoryFilter
      );

      tableData.value = productCategories.value;

      initialData.value.splice(0, tableData.value.length, ...tableData.value);

      loading.value = false;
    };

    const setModal = (id: string) => {
      isEditMode.value = true;

      const value = productCategories.value.find(
        (el) => el.productCategory.id == id
      );

      if (value) {
        modelValue.value = value.productCategory;
      }
    };

    const resetModel = () => {
      modelValue.value = { ...defaultModel };
    };

    const deleteHandler = async (id: string) => {
      const res = await DeleteItemService.delete(
        id as string,
        deleteProductCategory
      );

      if (res) {
        for (let i = 0; i < tableData.value.length; i++) {
          if (tableData.value[i].productCategory.id === id) {
            tableData.value.splice(i, 1);
          }
        }
      }
    };

    const searchFunc = async () => {
      await getData();
    };

    return {
      defaultFilter,
      loading,
      tableData,
      tableHeader,
      isEditMode,
      modelValue,
      checkedCustomers,
      showAdvanceFilter,
      deleteHandler,
      searchFunc,
      resetModel,
      setModal,
      breadcrumbs,
      permissions,
    };
  },
});
