
import { defineComponent, ref, watch } from "vue";
import { CreateOrEditProductCategoryDto } from "@/shared/service-proxies/service-proxies";

export default defineComponent({
  name: "product-category-view-modal",
  props: {
    modelValue: {
      required: false,
      type: () => Object as unknown as CreateOrEditProductCategoryDto,
    },
  },
  setup(props) {
    const formRef = ref<null | HTMLFormElement>(null);
    const ProductUnitViewModalRef = ref<null | HTMLElement>(null);

    let formData = ref<CreateOrEditProductCategoryDto>();

    watch(
      () => props.modelValue,
      () => {
        formData.value =
          props.modelValue as unknown as CreateOrEditProductCategoryDto;
      }
    );

    return {
      formData,
      formRef,
      ProductUnitViewModalRef,
    };
  },
});
